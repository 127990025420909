export default {
    auth: {
        sendCode: "/api/auth/send-code",
        validateCode: "/api/auth/validate-code",
    },
    users: {
        create: "/api/users",
        memberships: {
            create: (userUid) => `/api/users/${userUid}/memberships`,
            list: (userUid, page, perPage = 10) => {
                let searchParams = new URLSearchParams({
                    page,
                    per_page: perPage,
                });

                return `/api/users/${userUid}/memberships?` + searchParams.toString();
            },
        },
        phone_number: {
            sendCode: (userUid) => `/api/users/${userUid}/phone-number/send-code`,
            checkCode: (userUid) => `/api/users/${userUid}/phone-number/check-code`,
        },
        get: (uid) => `/api/users/${uid}`,
    },
    members: {
        create: "/api/members",
        bulkDelete: (slug) =>`/${slug}/memberships/bulk-wipe`,
        list: (
            slug,
            page = 1,
            perPage = 10,
            search = "",
            groups = [],
            accountStatuses = [],
            orderBy = "name",
            orderByDirection = "asc",
            fields = [],
            filtersOperator = "and",
            workStatuses = [],
            includeDeactivated = false
        ) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
                orderBy: orderBy,
                orderByDirection: orderByDirection,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            groups.forEach((group) => {
                searchParams.append("groups[]", group);
            });

            accountStatuses.forEach((status) => {
                searchParams.append("statuses[]", status);
            });

            workStatuses.forEach((status) => {
                searchParams.append("workStatuses[]", status);
            });

            fields.forEach((field) => {
                field.value = Array.isArray(field.value) ? field.value : [field.value];

                field.value.forEach((value) => {
                    searchParams.append(`fields[${field.uid}]`, value);
                });
            });

            if (includeDeactivated) {
                searchParams.append("deactivated", 1);
            }

            if (filtersOperator) {
                searchParams.append("operator", filtersOperator);
            }

            return `/${slug}/members?` + searchParams.toString();
        },
        leadership: (slug, page = 1, perPage = 10) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            searchParams.append("leader", 1);

            return `/${slug}/members?` + searchParams.toString();
        },
        download: (
            slug,
            search = "",
            groups = [],
            accountStatuses = "",
            orderBy = "name",
            orderByDirection = "asc",
            fields = [],
            workStatuses = []
        ) => {
            let searchParams = new URLSearchParams({
                orderBy: orderBy,
                orderByDirection: orderByDirection,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            groups.forEach((group) => {
                searchParams.append("groups[]", group);
            });

            accountStatuses.forEach((status) => {
                searchParams.append("statuses[]", status);
            });

            fields.forEach((field) => {
                searchParams.append(`fields[${field.uid}]`, field.value);
            });

            workStatuses.forEach((status) => {
                searchParams.append("workStatuses[]", status);
            });

            return `/${slug}/export-members?` + searchParams.toString();
        },
        createLeader: (slug, memberUid) => `/${slug}/members/${memberUid}/leader`,
        createMember: (slug) => `/${slug}/members`,
        manage: {
            update: (slug, memberUid) => `/${slug}/members/${memberUid}`,
            approve: (slug, memberUid) => `/${slug}/members/${memberUid}/approve`,
            deny: (slug, memberUid) => `/${slug}/members/${memberUid}/deny`,
            activate: (slug) => `/${slug}/members/restore`,
            deactivate: (slug, memberUid) => `/${slug}/members/${memberUid}`,
            resendInvitation: (slug, memberUid) =>
                `/${slug}/members/${memberUid}/resend-invitation`,
        },
        emails: {
            get: (slug, memberUid, isPrimary) =>
                `/${slug}/members/${memberUid}/emails?is_primary=${isPrimary}`,
            create: (slug, memberUid) => `/${slug}/members/${memberUid}/emails`,
            checkCode: (slug, memberUid) =>
                `/${slug}/members/${memberUid}/emails/check-code`,
            sendCode: (slug, memberUid) =>
                `/${slug}/members/${memberUid}/emails/send-code`,
            delete: (slug, memberUid, email) =>
                `/${slug}/members/${memberUid}/emails/${email}`,
        },
        phoneNumbers: {
            get: (slug, memberUid, isPrimary) =>
                `/${slug}/members/${memberUid}/phone-numbers?is_primary=${isPrimary}`,
            create: (slug, memberUid) =>
                `/${slug}/members/${memberUid}/phone-numbers`,
            checkCode: (slug, memberUid) =>
                `/${slug}/members/${memberUid}/phone-numbers/check-code`,
            sendCode: (slug, memberUid) =>
                `/${slug}/members/${memberUid}/phone-numbers/send-code`,
            delete: (slug, memberUid, phoneNumber) =>
                `/${slug}/api/members/${memberUid}/phone-numbers/${phoneNumber}`,
        },
        fields: {
            get: (slug, memberUid) => `/${slug}/members/${memberUid}/fields`,
            update: (slug, memberUid) => `/${slug}/members/${memberUid}/fields`,
        },
        roles: {
            add: (slug, memberUid) => `/${slug}/members/${memberUid}/roles`,
        },
        notifications: {
            get: (
                slug,
                memberUid,
                page = 1,
                perPage = 10,
                search = "",
                types = []
            ) => {
                let searchParams = new URLSearchParams({
                    page,
                    per_page: perPage,
                });

                types.forEach((type) => {
                    searchParams.append("type[]", type);
                });

                if (search.length > 0) {
                    searchParams.append("search", search);
                }

                return (
                    `/${slug}/members/${memberUid}/notifications?` +
					searchParams.toString()
                );
            },
            read: (slug, memberUid, notificationUid) =>
                `/${slug}/members/${memberUid}/notifications/${notificationUid}/read`,
            delete: (slug, memberUid, notificationUid) =>
                `/${slug}/members/${memberUid}/notifications/${notificationUid}`,
            types: (slug) => `/${slug}/notifications/types`,
        },
        pushNotifications: {
            store: (slug, memberUid) => `/${slug}/members/${memberUid}/web-tokens`,
            delete: (slug, memberUid, tokenUid) =>
                `/${slug}/members/${memberUid}/web-tokens/${tokenUid}`,
        },
        avatar: {
            store: (slug, memberUid) => `/${slug}/members/${memberUid}/avatar`,
            delete: (slug, memberUid) => `/${slug}/members/${memberUid}/avatar`,
        },
        get: (slug, memberUid) => `/${slug}/members/${memberUid}`,
        update: (slug, memberUid) => `/${slug}/members/${memberUid}`,
        showByUser: (slug, userUid = null) => `/${slug}/users/${userUid}/member`,
        getAvailableStatuses: (slug) => `/${slug}/members/statuses`,
        getAvailableWorkStatuses: (slug) => `/${slug}/available-work-statuses`,
        getProfileStatus: (slug, memberUid) =>
            `/${slug}/members/${memberUid}/profile-status`,
        getOnboardingStatus: (slug, memberUid) =>
            `/${slug}/members/${memberUid}/onboarding-status`,
        getFieldsStatus: (slug, memberUid) =>
            `/${slug}/members/${memberUid}/fields-status`,
        finishOnboarding: (slug, memberUid) =>
            `/${slug}/members/${memberUid}/finish-onboarding`,
        features: (slug) => `/${slug}/members/features`,
        widgets: {
            updateOrder: (slug, memberUid) => `/${slug}/members/${memberUid}/dashboard-widgets`,
        }
    },
    leaders: {
        update: (slug, leaderUid) => `/${slug}/leader/${leaderUid}`,
        delete: (slug, leaderUid) => `/${slug}/leader/${leaderUid}`,
    },
    importMembers: {
        list: (slug, page = 1) => `/${slug}/import-members?page=${page}`,
        listV2: (slug, page = 1) => `/${slug}/import-members-v2?page=${page}`,
        batchStoreV2: (slug) => `/${slug}/import-members-v2/batch`,
        batchUpdate: (slug) => `/${slug}/import-members/batch`,
        batchUpdateV2: (slug) => `/${slug}/import-members-v2/batch`,
        batchDelete: (slug) => `/${slug}/import-members/batch`,
        batchDeleteV2: (slug) => `/${slug}/import-members-v2/batch`,
        start: (slug) => `/${slug}/import-members/start`,
        bulkUpdateColumn: (slug) => `/${slug}/import-members/bulk-update`,
    },
    organizations: {
        search: (name) => {
            if (!name) {
                return "/api/organizations";
            }

            return `/api/organizations?name=${name}`;
        },
        get: (slug) => `/api/organizations/${slug}`,
        getExcept: (uids) => {
            let searchParams = new URLSearchParams();

            uids.forEach((uid) => {
                searchParams.append("without[]", uid);
            });

            return `/api/organizations/?` + searchParams.toString();
        },
        uploadLogo: (slug) => `/${slug}/logo`,
        uploadCover: (slug) => `/${slug}/cover`,
        deleteLogo: (slug) => `/${slug}/logo/delete`,
        deleteCover: (slug) => `/${slug}/cover/delete`,
        updateInfo: (slug) => `/${slug}/info`,
        update: (slug) => `/api/organizations/${slug}`,
    },
    organization: {
        getRoles: (slug) => `/${slug}/roles`,
    },
    invitation: {
        send: (slug) => `/${slug}/members`,
    },
    invitations: {
        get: (uid, token) => `/api/invitations/${uid}?token=${token}`,
        update: (uid, token) => `/api/invitations/${uid}?token=${token}`,
        phone_number: {
            sendCode: (uid, token) =>
                `/api/invitations/${uid}/phone-number/send-code?token=${token}`,
            checkCode: (uid, token) =>
                `/api/invitations/${uid}/phone-number/check-code?token=${token}`,
        },
    },
    fields: {
        get: (slug, nonGrouped, page = 1, perPage = 100, search = "") => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search && search.length > 0) {
                searchParams.append("name", search);
            }

            searchParams.append("non_grouped", nonGrouped);

            return `/${slug}/fields?` + searchParams.toString();
        },
        delete: (slug, uid) => `/${slug}/fields/${uid}`,
    },
    fieldsHeadlines: {
        post: (slug) => `/${slug}/profile-builder`,
    },
    defaultFields: {
        search: (search = '', page = 1, perPage = 10) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search && search.length > 0) {
                searchParams.append('label', search);
            }

            return `/api/fields?` + searchParams.toString();
        }
    },
    headlines: {
        get: (slug) => `/${slug}/field-headlines`,
    },
    groups: {
        create: (slug) => `/${slug}/groups`,
        get: (slug, page = 1, perPage = 10, search = "") => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search.length > 0) {
                searchParams.append("name", search);
            }

            return `/${slug}/groups?` + searchParams.toString();
        },
        delete: (slug, uid) => `/${slug}/groups/${uid}`,
        deleteChatGroup: (slug, uid) => `/${slug}/groups/${uid}/chat-group`,
        associateMembers: (slug, uid) => `/${slug}/groups/${uid}/members`,
        disassociateMembers: (slug, uid) => `/${slug}/groups/${uid}/members`,
        show: (slug, uid) => `/${slug}/groups/${uid}`,
        update: (slug, uid) => `/${slug}/groups/${uid}`,
        avatar: {
            store: (slug, groupUid) => `/${slug}/groups/${groupUid}/avatar`,
            delete: (slug, groupUid) => `/${slug}/groups/${groupUid}/avatar`,
        },
    },
    chat: {
        getAccessToken: (slug) => `/${slug}/chat_access_token`,
    },
    sms: {
        create: (slug) => `/${slug}/sms`,
        get: (
            slug,
            page = 1,
            perPage = 10,
            search = "",
            date = "",
            filter = ""
        ) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            if (date.length > 0) {
                searchParams.append("date", date);
            }

            switch (filter) {
            case "scheduled":
                searchParams.append("scheduled", 1);
                break;
            case "sent":
                searchParams.append("sent", 1);
                break;
            case "draft":
                searchParams.append("draft", 1);
                break;
            default:
                break;
            }

            return `/${slug}/sms?` + searchParams.toString();
        },
        update: (slug, uid) => `/${slug}/sms/${uid}`,
        showSMS: (slug, uid) => `/${slug}/sms/${uid}`,
        delete: (slug, uid) => `/${slug}/sms/${uid}`,
    },
    email: {
        create: (slug) => `/${slug}/emails`,
        get: (
            slug,
            page = 1,
            perPage = 10,
            search = "",
            date = "",
            filter = ""
        ) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            if (date.length > 0) {
                searchParams.append("date", date);
            }

            switch (filter) {
            case "scheduled":
                searchParams.append("scheduled", 1);
                break;
            case "sent":
                searchParams.append("sent", 1);
                break;
            case "draft":
                searchParams.append("draft", 1);
                break;
            case "bin":
                searchParams.append("bin", 1);
                break;
            default:
                break;
            }

            return `/${slug}/emails?` + searchParams.toString();
        },
        showEmail: (slug, uid) => `/${slug}/emails/${uid}`,
        update: (slug, uid) => `/${slug}/emails/${uid}`,
        delete: (slug, uid) => `/${slug}/emails/${uid}`,
    },
    smsInbox: {
        get: (
            slug,
            page = 1,
            perPage = 10,
            search = "",
            date = "",
            filter = ""
        ) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            if (date.length > 0) {
                searchParams.append("date", date);
            }

            switch (filter) {
            case "favorite":
                searchParams.append("favorite", 1);
                break;
            case "bin":
                searchParams.append("bin", 1);
                break;
            default:
                break;
            }

            return `/${slug}/sms-inbox?` + searchParams.toString();
        },
        update: (slug, uid) => `/${slug}/sms-inbox/${uid}`,
        delete: (slug, uid) => `/${slug}/sms-inbox/${uid}`,
    },
    emailInbox: {
        get: (
            slug,
            page = 1,
            perPage = 10,
            search = "",
            date = "",
            filter = ""
        ) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            if (date.length > 0) {
                searchParams.append("date", date);
            }

            switch (filter) {
            case "favorite":
                searchParams.append("favorite", 1);
                break;
            case "bin":
                searchParams.append("bin", 1);
                break;
            default:
                break;
            }

            return `/${slug}/email-inbox?` + searchParams.toString();
        },
        update: (slug, uid) => `/${slug}/email-inbox/${uid}`,
        delete: (slug, uid) => `/${slug}/email-inbox/${uid}`,
    },
    files: {
        create: (slug) => `/${slug}/files`,
        download: (slug, fileName) => `/${slug}/files/download/${fileName}`,
    },
    news: {
        create: (slug) => `/${slug}/articles`,
        get: (slug, uid) => `/${slug}/articles/${uid}`,
        update: (slug, uid) => `/${slug}/articles/${uid}`,
        delete: (slug, uid) => `/${slug}/articles/${uid}`,
        list: (
            slug,
            page = 1,
            perPage = 12,
            search = "",
            startDate = "",
            endDate = "",
            filters = "",
            tags = []
        ) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            if (startDate.length > 0) {
                searchParams.append("published_start", startDate);
            }

            if (endDate.length > 0) {
                searchParams.append("published_end", endDate);
            }

            switch (filters) {
            case "published":
                searchParams.append("published", 1);
                break;
            case "scheduled":
                searchParams.append("published", 0);
                break;
            case "draft":
                searchParams.append("draft", 1);
                break;
            case "bin":
                searchParams.append("bin", 1);
                break;
            case "liked":
                searchParams.append("liked", 1);
                break;
            case "all_news":
            default:
                searchParams.append("all_news", 1);
                break;
            }

            tags.forEach((tag) => {
                searchParams.append("tags[]", tag);
            });

            return `/${slug}/articles?` + searchParams.toString();
        },
        getPublicNews: (slug, page = 1, perPage = 10) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            return `/${slug}/public-news?` + searchParams.toString();
        },
        getPublicArticle: (slug, uid) => `/${slug}/public-news/${uid}`,
        like: (slug, uid) => `/${slug}/articles/${uid}/like`,
        unlike: (slug, uid) => `/${slug}/articles/${uid}/unlike`,
        share: (slug, uid) => `/${slug}/articles/${uid}/share`,
    },
    tags: {
        list: (slug, page = 1, perPage = 10, search = "") => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            return `/${slug}/tags?` + searchParams.toString();
        },
    },
    likes: {
        create: (slug, uid) => `/${slug}/articles/${uid}/like`,
        delete: (slug, uid) => `/${slug}/articles/${uid}/unlike`,
    },
    resources: {
        get: (slug, uid) => `/${slug}/resources/${uid}`,
        delete: (slug, uid) => `/${slug}/resources/${uid}`,
        list: (
            slug,
            page = 1,
            perPage = 12,
            search = "",
            startDate = "",
            endDate = "",
            type = "",
            tags = [],
            filter = "",
            orderBy = "",
            orderByDirection = "",
        ) => {
            let searchParams = new URLSearchParams({
                page,
                per_page: perPage,
            });

            if (search.length > 0) {
                searchParams.append("search", search);
            }

            if (startDate.length > 0) {
                searchParams.append("published_start", startDate);
            }

            if (endDate.length > 0) {
                searchParams.append("published_end", endDate);
            }

            if (type && type.length > 0) {
                searchParams.append("type", type);
            }

            tags.forEach((tag) => {
                searchParams.append("tags[]", tag);
            });

            if(orderBy && orderBy.length > 0) {
                searchParams.append("orderBy", orderBy);
            }

            if(orderByDirection && orderByDirection.length > 0) {
                searchParams.append("orderByDirection", orderByDirection);
            }

            if (filter && filter.length > 0) {
                switch (filter) {
                case 'bin':
                    searchParams.append("bin", 1);
                    break;
                case 'liked':
                    searchParams.append("liked", 1);
                    break;
                case 'draft':
                    searchParams.append('draft', 1);
                    break;
                case 'all_resources':
                default:
                    searchParams.append("all_resources", 1);
                }
            }

            return `/${slug}/resources?` + searchParams.toString();
        },
        like: (slug, uid) => `/${slug}/resources/${uid}/like`,
        unlike: (slug, uid) => `/${slug}/resources/${uid}/unlike`,
        show: (slug, uid) => `/${slug}/resources/${uid}`,
        share: (slug, uid) => `/${slug}/resources/${uid}/share`,
    },
    weblinks: {
        create: (slug) => `/${slug}/weblinks`,
        get: (slug, uid) => `/${slug}/weblinks/${uid}`,
        update: (slug, uid) => `/${slug}/weblinks/${uid}`,
    },
    pages: {
        create: (slug) => `/${slug}/pages`,
        get: (slug, uid) => `/${slug}/pages/${uid}`,
        update: (slug, uid) => `/${slug}/pages/${uid}`,
    },
    resourceCategories: {
        get: (slug) => `/${slug}/resource-categories`,
    },
};
